import type { ComponentProps } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

import cn from "../../util/cn.js";
import { LINE_LIMIT_CLASSES, type LineLimit } from "../../util/LineLimit.js";

type ReactMarkdownComponents = ComponentProps<typeof ReactMarkdown>["components"];

interface Props {
  className?: string;
  children?: string | null;
  lineLimit?: LineLimit;
  components?: ReactMarkdownComponents;
}

const DEFAULT_COMPONENTS: ReactMarkdownComponents = {
  h1: ({ children }) => {
    return <h1 className="text-2xl font-semibold">{children}</h1>;
  },
  h2: ({ children }) => {
    return <h2 className="text-xl font-medium">{children}</h2>;
  },
  h3: ({ children }) => {
    return <h3 className="text-lg font-medium">{children}</h3>;
  },
  h4: ({ children }) => {
    return <h4 className="text-base font-medium">{children}</h4>;
  },
  h5: ({ children }) => {
    return <h5 className="text-sm font-medium">{children}</h5>;
  },
  h6: ({ children }) => {
    return <h6 className="text-xs font-medium">{children}</h6>;
  },
  a: ({ children, href }) => {
    return (
      <a
        href={href}
        rel="noopener noreferrer"
        target="_blank"
        className="underline hover:bg-accent/10"
      >
        {children}
      </a>
    );
  },
  hr: () => {
    return <hr className="my-4 h-1 border-0 border-b-2 border-dotted" />;
  },
  strong: ({ children }) => {
    return <strong className="font-medium">{children}</strong>;
  },
};

const Markdown = ({ className, children, lineLimit, components }: Props) => {
  const mergedComponents = { ...DEFAULT_COMPONENTS, ...components };

  return (
    <div
      className={cn(
        className,
        "prose break-words",
        lineLimit ? LINE_LIMIT_CLASSES[lineLimit] : undefined
      )}
    >
      {children && (
        <ReactMarkdown remarkPlugins={[remarkGfm]} components={mergedComponents}>
          {children}
        </ReactMarkdown>
      )}
    </div>
  );
};

export default Markdown;
